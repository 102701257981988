import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from 'semantic-ui-react';
import store from '../../store';

const Section = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MyForm = styled.div`
    max-width: 600px;
    width: 100%;
    max-width: 350px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
    width: 100%;
`;

const ForgotLine = styled.div`
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
`;

interface LoginProps {
    modalView: boolean
}

interface LoginState {
    redirect: string,
    errorMsg: string,
    username: string,
    password: string,
    loading: boolean
}

class ClientLoginComponent extends React.Component<LoginProps, LoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            redirect: "",
            errorMsg: "",
            username: "",
            password: "",
            loading: false
        }
        this.submit = this.submit.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    onUsernameChange(e?: any) {
        this.setState({ username: e.target.value });
    }

    onPasswordChange(e?: any) {
        this.setState({ password: e.target.value });
    }

    submit = () => {
        if (!this.state.username) {
            this.setState({ errorMsg: "Та хэрэглэгчийн нэр оруулна уу" })
            this.scrollToForm()
            return;
        }

        if (!this.state.password) {
            this.setState({ errorMsg: "Та нууц үг оруулна уу" })
            this.scrollToForm()
            return;
        }

        this.setState({ errorMsg: "", loading: true })

        fetch(process.env.REACT_APP_CHEF_API + 'login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: this.state.username, password: this.state.password })
        })
        .then((res: any) => {
            if (!res.ok) {
                res.json().then((resJson: any) => {
                    this.setState({ errorMsg: resJson.errorMsg, loading: false}, this.scrollToForm)
                })
            } else {
                res.json().then((resJson: any) => {
                    store.dispatch({
                        type: 'clientToken',
                        clientToken: resJson.token
                    })
                    localStorage.setItem('clientToken', resJson.token)
                    this.getClient(resJson.token);
                })
            }
            this.setState({ loading: false })
        })
        .catch(err => {
            console.error(err)
            this.setState({ errorMsg: "Сүлжээний алдаа", loading: false })
        })
    }

    getClient(token: string) {
        fetch(process.env.REACT_APP_CHEF_API + 'client', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
        .then((res: any) => {
            if (res.ok) {
                res.json().then((resJson: any) => {
                    store.dispatch({
                        type: 'authenticateClient',
                        client: resJson.client
                    })
                    let redirectUrl = store.getState().redirectUrl || "/";
                    console.log("Redirecting to: " + redirectUrl)
                    this.setState({ redirect: redirectUrl })
                    store.dispatch({ type: "redirectUrl", data: "" })
                })
            }
        })
        .catch((err: any) => {
            console.error(err)
            this.setState({ errorMsg: "Сүлжээнд алдаа гарлаа", loading: false })
        })
    }

    scrollToForm = () => {
        let element = document.getElementById("login-form");
        if (element) {
            element.scrollIntoView();
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="page login-page margin-top-50">
                <Section>
                    <div className="logo-text">
                        <img src="logo.jpg" alt="logo" className="logo-img" width="100"/>
                    </div>
                </Section>

                <div className="flex-div">
                    <MyForm id="login-form">
                        { this.state.errorMsg ? 
                            <FormGroup>
                                <Icon name="warning sign" color='red'/>
                            {this.state.errorMsg}
                            </FormGroup> : null
                        }
                        <FormGroup>
                            <div className="width-100 ui left icon input">
                                <i className="user icon"></i>
                                <input type="email" className="width-100" name="email" value={this.state.username} onChange={this.onUsernameChange} placeholder="Имэйл" />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="width-100 ui left icon input">
                                <i className="lock icon"></i>
                                <input className="width-100" type="password" name="password" value={this.state.password} onChange={this.onPasswordChange} placeholder="Нууц үг" />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button className="ui fluid large red submit button" disabled={this.state.loading} onClick={this.submit}>Нэвтрэх</Button>
                            {
                                this.props.modalView ? null : <Link to="/forgot-password">
                                    <ForgotLine>Шинэ нууц үг үүсгэх</ForgotLine>
                                </Link>
                            }
                        </FormGroup>
                        <FormGroup>
                        {
                            this.props.modalView ? null :
                            <Link to="/signup">
                                <Button className="width-100" color='grey'>Шинээр бүртгүүлэх</Button>
                            </Link>
                        }
                        </FormGroup>
                    </MyForm>
                </div>
            </div>
        );
    }
}

export default ClientLoginComponent;